// @import url("https://fonts.cdnfonts.com/css/neue-haas-grotesk-display-pro");

@import url("https://prod-doc.fra1.digitaloceanspaces.com/clashdisplay.css");
@import url("https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&display=swap");

/**
* @license
*
* Font Family: Clash Display
* Designed by: Indian Type Foundry
* URL: https://www.fontshare.com/fonts/clash-display
* © 2024 Indian Type Foundry
*
* Clash Display Extralight 
* Clash Display Light 
* Clash Display Regular 
* Clash Display Medium 
* Clash Display Semibold 
* Clash Display Bold 
* Clash Display Variable (Variable font)

*
*/
// @font-face {
//   font-family: "ClashDisplay";
//   src: url("../assets/fonts/ClashDisplay/ClashDisplay-Extralight.woff2") format("woff2"),
//     url("../assets/fonts/ClashDisplay/ClashDisplay-Extralight.woff") format("woff"),
//     url("../assets/fonts/ClashDisplay/ClashDisplay-Extralight.ttf") format("truetype");
//   font-weight: 200;
//   font-display: swap;
//   font-style: normal;
// }
// @font-face {
//   font-family: "ClashDisplay";
//   src: url("../assets/fonts/ClashDisplay/ClashDisplay-Light.woff2") format("woff2"),
//     url("../assets/fonts/ClashDisplay/ClashDisplay-Light.woff") format("woff"),
//     url("../assets/fonts/ClashDisplay/ClashDisplay-Light.ttf") format("truetype");
//   font-weight: 300;
//   font-display: swap;
//   font-style: normal;
// }
// @font-face {
//   font-family: "ClashDisplay";
//   src: url("../assets/fonts/ClashDisplay/ClashDisplay-Regular.woff2") format("woff2"),
//     url("../assets/fonts/ClashDisplay/ClashDisplay-Regular.woff") format("woff"),
//     url("../assets/fonts/ClashDisplay/ClashDisplay-Regular.ttf") format("truetype");
//   font-weight: 400;
//   font-display: swap;
//   font-style: normal;
// }
// @font-face {
//   font-family: "ClashDisplay";
//   src: url("../assets/fonts/ClashDisplay/ClashDisplay-Medium.woff2") format("woff2"),
//     url("../assets/fonts/ClashDisplay/ClashDisplay-Medium.woff") format("woff"),
//     url("../assets/fonts/ClashDisplay/ClashDisplay-Medium.ttf") format("truetype");
//   font-weight: 500;
//   font-display: swap;
//   font-style: normal;
// }
// @font-face {
//   font-family: "ClashDisplay";
//   src: url("../assets/fonts/ClashDisplay/ClashDisplay-Semibold.woff2") format("woff2"),
//     url("../assets/fonts/ClashDisplay/ClashDisplay-Semibold.woff") format("woff"),
//     url("../assets/fonts/ClashDisplay/ClashDisplay-Semibold.ttf") format("truetype");
//   font-weight: 600;
//   font-display: swap;
//   font-style: normal;
// }
// @font-face {
//   font-family: "ClashDisplay";
//   src: url("../assets/fonts/ClashDisplay/ClashDisplay-Bold.woff2") format("woff2"),
//     url("../assets/fonts/ClashDisplay/ClashDisplay-Bold.woff") format("woff"),
//     url("../assets/fonts/ClashDisplay/ClashDisplay-Bold.ttf") format("truetype");
//   font-weight: 700;
//   font-display: swap;
//   font-style: normal;
// }

/**
* This is a variable font
* You can control variable axes as shown below:
* font-variation-settings: wght 700.0;
*
* available axes:
'wght' (range from 200.0 to 700.0
*/
// @font-face {
//   font-family: "ClashDisplay";
//   src: url("../assets/fonts/ClashDisplay/ClashDisplay-Variable.woff2") format("woff2"),
//     url("../assets/fonts/ClashDisplay/ClashDisplay-Variable.woff") format("woff"),
//     url("../assets/fonts/ClashDisplay/ClashDisplay-Variable.ttf") format("truetype");
//   font-weight: 200 700;
//   font-display: swap;
//   font-style: normal;
// }

// @font-face {
//   font-family: "InterTight";
//   src: url("../assets/fonts/InterTight/InterTight-VariableFont_wght.ttf") format("truetype");
//   font-weight: 200 700;
//   font-display: swap;
//   font-style: normal;
// }

:root {
  --focusShadow: 0 0 0 3px red;
  --sideNavWidth: calc(100% - 83vw);
  --focusColor: #3385f76f;
  --transition: "all .4s cubic-bezier(0.4, 0, 0.2, 1)";
  // color: #a265f7

  // @media screen and (max-device-width: 1080px) {
  //   --sideNavWidth: calc(100% - 79vw);
  // }
}

// html,
// body {
//   padding: 0;
//   margin: 0;
//   font-family: "Inter", sans-serif;
//   font-display: optional;
//   background: white;
// }

* {
  box-sizing: border-box;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 1;
  background: url("../assets/svg/calendar.svg") no-repeat;
  width: 19.5px;
  height: 19.5px;
  cursor: pointer;
}

// @font-face {
//   font-family: "Polysans Trial";
//   src: local("Polysans Trial Neutral"), local("PolysansTrial-Neutral"),
//     url("https://prod-docs.bitmama.io/fonts/Polysans/PolySansTrial-Slim.otf") format("opentype");
//   font-weight: 400;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Polysans Trial";
//   src: local("Polysans Trial Neutral Italic"), local("PolysansTrial-Neutral"),
//     url("https://prod-docs.bitmama.io/fonts/Polysans/PolySansTrial-SlimItalic.otf") format("opentype");
//   font-weight: 400;
//   font-style: italic;
// }

// @font-face {
//   font-family: "Polysans Trial";
//   src: local("Polysans Trial Neutral"), local("PolysansTrial-Neutral"),
//     url("https://prod-docs.bitmama.io/fonts/Polysans/PolySansTrial-Neutral.otf") format("opentype");
//   font-weight: 500;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Polysans Trial";
//   src: local("Polysans Trial Neutral Italic"), local("PolysansTrial-Neutral"),
//     url("https://prod-docs.bitmama.io/fonts/Polysans/PolySansTrial-NeutralItalic.otf") format("opentype");
//   font-weight: 500;
//   font-style: italic;
// }

// @font-face {
//   font-family: "Polysans Trial";
//   src: local("Polysans Trial Median"), local("PolysansTrial-Median"),
//     url("https://prod-docs.bitmama.io/fonts/Polysans/PolySansTrial-Median.otf") format("opentype");
//   font-weight: 600;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Polysans Trial";
//   src: local("Polysans Trial Median Italic"), local("PolysansTrial-MedianItalic"),
//     url("https://prod-docs.bitmama.io/fonts/Polysans/PolySansTrial-MedianItalic.otf") format("opentype");
//   font-weight: 600;
//   font-style: italic;
// }

// @font-face {
//   font-family: "Polysans Trial";
//   src: local("Polysans Trial Bulky"), local("PolysansTrial-Bulky"),
//     url("https://prod-docs.bitmama.io/fonts/Polysans/PolySansTrial-Bulky.otf") format("opentype");
//   font-weight: 700;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Polysans Trial";
//   src: local("Polysans Trial Bulky Italic"), local("PolysansTrial-BulkyItalic"),
//     url("https://prod-docs.bitmama.io/fonts/Polysans/PolySansTrial-BulkyItalic.otf") format("opentype");
//   font-weight: 700;
//   font-style: italic;
// }

// @font-face {
//   font-family: "Polysans Trial";
//   src: url("../assets/fonts/BasierCircle/BasierCircle-SemiBold.eot");
//   src: local("Basier Circle SemiBold"), local("BasierCircle-SemiBold"),
//     url("../assets/fonts/BasierCircle/BasierCircle-SemiBold.eot?#iefix")
//       format("embedded-opentype"),
//     url("../assets/fonts/BasierCircle/BasierCircle-SemiBold.woff2")
//       format("woff2"),
//     url("../assets/fonts/BasierCircle/BasierCircle-SemiBold.woff")
//       format("woff"),
//     url("../assets/fonts/BasierCircle/BasierCircle-SemiBold.ttf")
//       format("truetype");
//   font-weight: 600;
//   font-style: normal;
// }

// @import "@radix-ui/colors/black-alpha.css";
// @import "@radix-ui/colors/mauve.css";
// @import "@radix-ui/colors/violet.css";

/* reset */
// button {
//   all: unset;
// }

.SelectTrigger {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 4px;
  padding: 0 0.8rem 1px 20px;
  font-size: 16px;
  line-height: 1;
  min-height: 40px;
  height: 60px;
  gap: 5px;
  background-color: #ebecec;
  border-radius: 10px;
  border: 1.4px solid transparent;
  color: var(--bitmama-colors-black);
  box-shadow: 0 2px 10px var(--black-a7);
  font-family: var(--bitmama-fonts-heading);
  outline: 1px solid transparent;
  text-align: left;

  transition-property: var(--bitmama-transition-property-common);
  transition-duration: var(--bitmama-transition-duration-normal);

  will-change: transform;
}

.SelectTrigger span:first-child {
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.SelectTrigger:hover {
  background-color: var(--bitmama-colors-gray-200);
  border-color: var(--bitmama-colors-primary-600);
  border-width: 1.4px;
}

.SelectTrigger:focus {
  box-shadow: var(--bitmama-shadows-outline);
  border-color: var(--bitmama-colors-primary-600);
}

.SelectTrigger[data-placeholder] {
  color: var(--bitmama-colors-grey-300);
}

.SelectTrigger[data-disabled] {
  // background: var(--bitmama-colors-grey-300);
  opacity: 0.5;
  // pointer-events: none;
  cursor: not-allowed;
}

.SelectIcon {
  color: var(--bitmama-colors-black);
  justify-self: flex-end;
}

.SelectContent {
  overflow: hidden;
  background-color: rgba(#fff, 0.96);
  border-radius: 6px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  font-family: var(--bitmama-fonts-heading);
  z-index: 9999999999999999;
  border: 1px solid transparent;
  border-color: rgba(#000, 0.2);
  backdrop-filter: blur(10px);
  overflow-y: auto;
  // max-height: 500px;
  // width: 120%;
}

.SelectViewport {
  padding: 5px;
}

.SelectItem {
  font-size: 16px;
  line-height: 1;
  color: black;
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 25px;
  // padding: 0 35px 0 25px;
  padding: 20px 10px;
  position: relative;
  user-select: none;
  background-color: transparent;
  cursor: pointer;
  gap: 8px;

  transition-property: var(--bitmama-transition-property-common);
  transition-duration: var(--bitmama-transition-duration-normal);
}

.SelectItem[data-disabled] {
  color: var(--bitmama-colors-grey-150);
  pointer-events: none;
}

.SelectItem[data-highlighted] {
  outline: none;
  background-color: var(--bitmama-colors-grey-100);
  color: var(--bitmama-colors-black);

  transition-property: var(--bitmama-transition-property-common);
  transition-duration: var(--bitmama-transition-duration-normal);
}

.SelectItemIcon {
  margin-right: 20px;
}

.SelectLabel {
  padding: 0 25px;
  font-size: 12px;
  line-height: 25px;
  color: var(--mauve-11);
}

.SelectSeparator {
  height: 1px;
  background-color: var(--violet-6);
  margin: 5px;
}

.SelectItemIndicator {
  position: absolute;
  right: 10px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.SelectScrollButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: white;
  color: var(--violet-11);
  cursor: default;
}

body.chakra-ui-dark {
  .SelectTrigger {
    background-color: var(--bitmama-colors-dark-cardBg);
    color: white;
    box-shadow: 0 2px 10px var(--black-a7);

    &:hover {
      border-color: var(--bitmama-colors-secondary-600);
    }

    &:focus {
      box-shadow: var(--bitmama-shadows-darkOutline);
      border-color: var(--bitmama-colors-secondary-600);
    }
  }

  .SelectTrigger[data-placeholder] {
    color: white;
  }

  .SelectContent {
    background-color: rgba(#252525, 0.99);
    color: white !important;
    backdrop-filter: blur(10px);
    border-color: rgba(#000, 0.5);
  }

  .SelectItem {
    color: white;
  }

  .SelectItem[data-highlighted] {
    outline: none;
    background-color: #171717;
    color: white;
  }

  .SelectScrollButton {
    color: black;
  }
}

.driver-active .driver-overlay,
.driver-active * {
  pointer-events: none;
}
.driver-active .driver-active-element,
.driver-active .driver-active-element *,
.driver-popover,
.driver-popover * {
  pointer-events: auto;
}

$px: 28px;

@keyframes animate-fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.driver-fade .driver-overlay {
  animation: animate-fade-in 0.2s ease-in-out;
}

.driver-fade .driver-popover {
  animation: animate-fade-in 0.2s;
}

.driver-popover {
  all: unset;
  box-sizing: border-box;
  color: #2d2d2d;
  margin: 0;
  //   padding: 15px;
  border-radius: 17px;
  min-width: 500px;
  max-width: 500px;
  box-shadow: 0 1px 10px #0006;
  z-index: 1000000000;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #fff;
  //   z-index: 99999999999999999999999999999999999999999;
}

.driver-popover * {
  font-family: "Neue Haas Grotesk Display Pro", sans-serif;
}

.driver-popover-title {
  font: 20px / 600 var(--bitmama-fonts-heading, sans-serif);
  font-weight: 700;
  display: block;
  position: relative;
  line-height: 1.5;
  zoom: 1;
  margin: 0;

  margin-top: 10px;
  padding: 18px $px;
}

.driver-popover-close-btn {
  all: unset;
  position: absolute;
  top: $px;
  right: $px;
  width: 28px;
  height: 28px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  color: #d2d2d2;
  z-index: 1;
  text-align: center;
  transition: color;
  transition-duration: 0.2s;
  background: #303131;
  border-radius: 8px;
  font-size: 26px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  color: white !important;

  &:hover,
  &:focus {
    color: white !important;
  }
}

.driver-popover-close-btn:hover,
.driver-popover-close-btn:focus {
  color: #2d2d2d;
}

.driver-popover-title[style*="block"] + .driver-popover-description {
  margin-top: 5px;
}

.driver-popover-description {
  margin-bottom: 0;
  font: 18px / 500 sans-serif;
  line-height: 1.5;
  font-weight: 400;
  zoom: 1;
  padding: 0 $px;
  margin-top: 18px;
}

.driver-popover-footer {
  margin-top: 28px;
  text-align: right;
  zoom: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border: 1px solid transparent;
  border-top-color: #cacbca;
  padding: 18px $px;
}

.driver-popover-progress-text {
  font-size: 16px;
  font-weight: 600;
  color: #727272;
  zoom: 1;
}

.driver-popover-footer button {
  all: unset;
  display: inline-block;
  box-sizing: border-box;
  text-decoration: none;
  //   text-shadow: 1px 1px 0 #fff;
  background-color: var(--bitmama-colors-primary-default);
  font: 16px / 600 var(--bitmama-fonts-heading, sans-serif);
  cursor: pointer;
  outline: 0;
  zoom: 1;
  line-height: 1.3;
  //   border: 1px solid #ccc;
  border-radius: 8px;
  color: white;
  padding: 8px 16px;

  &:hover {
    background: var(--bitmama-colors-primary-default) !important;
    color: white;
    box-shadow: var(--bitmama-shadows-outline);
    transition-property: var(--bitmama-transition-property-common);
    transition-duration: var(--bitmama-transition-duration-ultra-slow);
    transition-timing-function: var(--bitmama-transition-easing-ease-out);
  }

  &:focus {
    box-shadow: var(--bitmama-shadows-outline);
    transition-property: var(--bitmama-transition-property-common);
    transition-duration: var(--bitmama-transition-duration-ultra-slow);
    transition-timing-function: var(--bitmama-transition-easing-ease-out);
  }
}

.driver-popover-footer .driver-popover-btn-disabled {
  opacity: 0.5;
  pointer-events: none;
}

:not(body):has(> .driver-active-element) {
  overflow: hidden !important;
}

.driver-no-interaction,
.driver-no-interaction * {
  pointer-events: none !important;
}

.driver-popover-footer button:hover,
.driver-popover-footer button:focus {
  background-color: #f7f7f7;
}

.driver-popover-navigation-btns {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}

.driver-popover-navigation-btns button + button {
  margin-left: 4px;
}

.driver-popover-arrow {
  content: "";
  position: absolute;
  border: 5px solid #fff;
}

.driver-popover-arrow-side-over {
  display: none;
}

.driver-popover-arrow-side-left {
  left: 100%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-top-color: transparent;
}
.driver-popover-arrow-side-right {
  right: 100%;
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-top-color: transparent;
}
.driver-popover-arrow-side-top {
  top: 100%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.driver-popover-arrow-side-bottom {
  bottom: 100%;
  border-left-color: transparent;
  border-top-color: transparent;
  border-right-color: transparent;
}
.driver-popover-arrow-side-center {
  display: none;
}
.driver-popover-arrow-side-left.driver-popover-arrow-align-start,
.driver-popover-arrow-side-right.driver-popover-arrow-align-start {
  top: 15px;
}
.driver-popover-arrow-side-top.driver-popover-arrow-align-start,
.driver-popover-arrow-side-bottom.driver-popover-arrow-align-start {
  left: 15px;
}
.driver-popover-arrow-align-end.driver-popover-arrow-side-left,
.driver-popover-arrow-align-end.driver-popover-arrow-side-right {
  bottom: 15px;
}
.driver-popover-arrow-side-top.driver-popover-arrow-align-end,
.driver-popover-arrow-side-bottom.driver-popover-arrow-align-end {
  right: 15px;
}
.driver-popover-arrow-side-left.driver-popover-arrow-align-center,
.driver-popover-arrow-side-right.driver-popover-arrow-align-center {
  top: 50%;
  margin-top: -5px;
}
.driver-popover-arrow-side-top.driver-popover-arrow-align-center,
.driver-popover-arrow-side-bottom.driver-popover-arrow-align-center {
  left: 50%;
  margin-left: -5px;
}
.driver-popover-arrow-none {
  display: none;
}

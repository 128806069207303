.sidebar {
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 3px;
    visibility: hidden;
    opacity: 0;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 25px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 25x;
    background: transparent;
  }
}

.sidebar:hover {
  &::-webkit-scrollbar-thumb {
    // background: rgb(136, 136, 136);
    background: transparent;
    opacity: 0;
  }

  &::-webkit-scrollbar {
    visibility: visible;
    background: transparent;
  }
}

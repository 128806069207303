$xl: 87.5em; // 4k and hi-res desktop
$lg: 60em; // 1080 and hi-res laptops
$md: 40em; // hi-res tablets and low-res laptops
$sm: 28em; // mobile and low-res tablets
$xs: 20em; // mobile only

$primaryBg: #0d4740;
$secondaryBg: #64a743;

.chakra-ui-light,
.chakra-ui-dark {
  margin: 0;
  box-sizing: border-box;
}

#boundary {
  overflow: hidden;
}

.chakra-ui-light #boundary {
  background-color: white;
}

.chakra-ui-dark #boundary {
  background-color: #121212;
}

.hstack {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 8px 0;
}

#boundary_container {
  max-width: $lg;
  width: 100%;
  font-family: "Neue Haas Grotesk Display Pro", sans-serif;
}

#text {
  line-height: 30px;
}

.chakra-ui-light #text {
  color: #383838;
}

.btn {
  border: none;
  border-radius: 64px;
  color: white;
  font-family: Polysans Trial;
  font-size: 16px;
  transition: all 200ms cubic-bezier(0.215, 0.61, 0.355, 1);

  .rotate {
    transform: rotate(0deg);
    transition: transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}

.btn.btn--outline {
  background-color: transparent !important;
  border: 1px solid transparent;
}

.chakra-ui-light .btn {
  background-color: $primaryBg;

  &:hover {
    background-color: $secondaryBg;
    transition: all 200ms cubic-bezier(0.215, 0.61, 0.355, 1);

    .rotate {
      transform: rotate(90deg);
      transition: transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }

  &:active {
    transform: scale(0.99);
    transition: transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  &.btn--outline {
    border-color: $primaryBg;
    color: $primaryBg;

    &:hover {
      border-color: $secondaryBg;
      color: $secondaryBg;
      transition: all 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
}

.chakra-ui-dark .btn {
  background-color: $secondaryBg;

  &:hover {
    background-color: $primaryBg;
    transition: all 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  &:active {
    transform: scale(0.99);
    transition: transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  &.btn--outline {
    border-color: $secondaryBg;
    color: $secondaryBg;

    &:hover {
      border-color: $primaryBg;
      color: $primaryBg;
      transition: all 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
}
